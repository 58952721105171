import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Passionate problem solver`}</p>
    </blockquote>
    <p>{`I'm a recent grad from NC State University, I've won 1st place in a NASA competition, and I've helped send a semi-autonomous robotic arm into space. I constantly go above and beyond, have an insatiable drive for problem solving, and am always ready to solve challenges.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Personal Projects`}</h2>
    <ProjectCard title="NASA Swarmathon" links={["swarm_winner", "swarm_detection", "swarm_sensors", "swarm_map"]} bg="linear-gradient(330deg, #1747A5 0%, #010926 65%, #AC1D3B 100%)" mdxType="ProjectCard">
  Detection of AprilTag and decision making code behind Swarmathon rovers drop-off state. Based on the positioning and orientation of the tags, the swarm rover would decide what direction to turn in order to ensure successful drop-off
    </ProjectCard>
    <ProjectCard title="COVID-19 Tracker" links={["login.png", "stats-pie", "stats-infections1.png", "stats-infections2.png"]} bg="linear-gradient(to bottom left, #8187a5 0%, #5D6798 65%, #1747A5 100%)" mdxType="ProjectCard">
   Designed and implemented for iTrust2-v4. It adds the ability for Health Care Providers (HCPs) to upload patient COVID data. The data is then plotted using Charts.js for easy interpretation.
    </ProjectCard>
    <ProjectCard title="GradeSnap!" links={["login_screen.png", "teacher_portal.png", "student_portal.png"]} bg="linear-gradient(to left top, #F3C643 0%, #C32E3B 65%, #AC1D3B 100%)" mdxType="ProjectCard">
  Grade Snap is an integrated learning system for students. It allows teachers to provide traditional learning materials such as paper worksheets, while giving digital feedback in real-time.
    </ProjectCard>
    <ProjectCard title="ISS Tracker" links={["ISS.png", "ISS_no_bg.png"]} bg="linear-gradient(to left top, #576030 0%, #193F0E 65%, #130133 100%)" mdxType="ProjectCard">
  Retrieves the The International Space Station's latitude and longitude in low Earth's orbit via ISS-NOW json API. Its coordinates are then plotted on a map and updated every second, drawing a line of it's path. It will let you know when the ISS is flying close to your location!
    </ProjectCard>
    <ProjectCard title="Phone2Word" links={["converted.png", "main_page.png"]} bg="linear-gradient(to left bottom, #353B40 0%, #353B40 65%, #3477F6 100%)" mdxType="ProjectCard">
  Never forget another phone number with this useful conversion tool! Simply enter a 10-digit phone number, and it will convert it into two easy to remember words. Inspired by What3Words, phone2words stives to accomplish the same task, except with phone numbers.
    </ProjectCard>
    <ProjectCard title="Sell Stick" links={["sellstick stats.png"]} bg="linear-gradient(to right bottom, #261E0E 0%, #E18E39 65%, #E18E39 100%)" mdxType="ProjectCard">
  An open-source Spigot plugin/add-on that gives players a quick and easy way to sell contents of chests on Minecraft servers. Plugin run on ~6,000 servers with an active discord and development
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      